import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import './LoginForm.css';

const AuthLogin = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  return (
    !isAuthenticated && (
    <>
        <div className='container'>
             <form className="login" action="">
                <img className="ssohub" src="ssohub2.png" alt="sso-hub-icon" />
                 <button className='button' onClick={() => loginWithRedirect()}>SIGN IN</button>
             </form>
                <img className="containerRight" src="logo2.png" alt="Protenus Logo" />
         </div>
         
    </>
    )

  )
};

export default AuthLogin;

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import './Profile.css';




const Profile = () => {


  const { user, isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated && (
     
      <div className="profile-container">
         {/* NavBar LOGO */}
        <div className="loginright">
          <div>
            <h2 className="user">Hello, {user.name}!</h2>
            <img className="welcomeimage" src="welcome2.png" alt="Protenus.com" />
            
          </div>
          {/* Protenus UI */}
          <a href="https://www.protenus.com/" target="_blank" rel="noopener noreferrer">
            <button className="linkButton"> 
              <img className="linkimage" src="Protenus v2.png" alt="ProtenusUI" />
            </button>
          </a>

          <br />
          {/* Help Center */}
          <a href="https://protenus1719603634.zendesk.com/auth/v2/login/continue_with_sso_tracking?auth_origin=30825480210067%2Cfalse%2Ctrue&brand_id=30825480210067&locale=en-us&remote_auth_id=31658403726867&return_to=https%3A%2F%2Fprotenus1719603634.zendesk.com%2Fhc%2Fen-us&role=end_user&theme=hc" target="_blank" rel="noopener noreferrer">
            <button className="linkButton">
              <img className="linkimage" src="Help Center v2.png" alt="Help Center" />
            </button>
          </a>

          <br />
          {/* Academy */}
          <a href="https://test-protenus.skilljar.com/auth/login" target="_blank" rel="noopener noreferrer">
            <button className="linkButton">
              <img className="linkimage" src="Protenus Academy v2.png" alt="Academy" />
            </button>
          </a>

          <br />
           {/* Habitat */}
          <a href="https://protenus-en-sandbox-community.insided.com/ssoproxy/login?ssoType=saml" target="_blank" rel="noopener noreferrer">
            <button className="linkButton">
              <img className="linkimage" src="PANDAS Habitat v2.png" alt="Habitat" />
              </button>
          </a>
        </div>
        <div>
          <img className="containerleft" src="colorpandy.png" alt="Protenus Logo" />
        </div>
      </div>
    )
  );
};

export default Profile;

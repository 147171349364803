import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import './Navbar.css';
import LogoutButton from "./LogoutButton";
import { IoPersonCircleSharp } from "react-icons/io5";


const Navbar = () => { 
    
    const { isAuthenticated } = useAuth0();

    function toggleIcon() {
        let subMenu = document.getElementById("subMenu");
        subMenu.classList.toggle("open-icon");
    }

    return (
        <div className="header">
            {!isAuthenticated && (
                <>
                <a href="https://www.protenus.com/">
                <img className="navbarLogo" src="navbarLogo.png" alt="Protenus Logo" />
                </a>
                </>
            )}
            {isAuthenticated &&(
                <>
                    <div className="header">
                        <a href="https://www.protenus.com/">
                            <img className="navbarLogo" src="navbarLogo.png" alt="Protenus Logo" />
                        </a>
                        <div>
                            <IoPersonCircleSharp size="3em" className="usericon" onClick={toggleIcon} />
                        </div>
                            <div className="sub-menu-wrap" id="subMenu">
                                <div className="logoutButton">
                                    <div className="logoutButton"> 
                                        <LogoutButton /> 
                                    </div>
                                </div>
                            </div>
                    </div>
                </>
            )}
        </div>
    )
  };
  
  export default Navbar;
  

               
//   onClick={toggleIcon